<!--登录页面-->
<template>
  <div id="login">
    <div class="left">
      <img src="../../../public/images/login/login01.png" />
    </div>
    <div class="right">
      <div class="container">
        <div class="logo">
          <img src="../../../public/images/login/login02.png" />
        </div>
        <div v-if="kind===1" class="login">
          <span>{{ $t("login.Q1") }}</span>
          <a-form-model ref="ruleForm" :model="form" :rules="rules">
            <a-form-model-item prop="name">
              <a-input  ref="username"  v-model="form.name"  style="height:40px;"  :placeholder="$t('login.Q2')" />
            </a-form-model-item>
            <a-form-model-item prop="password1">
              <a-input-password :style="{height:'40px'}"  v-model="form.password1" :placeholder="$t('login.Q3')" />
            </a-form-model-item>

              <div class="validateCodeWrap">
                <div class="validateCodeWrap-input">
                  <a-form-model-item prop="validateCode" class="validateCode">
                    <a-input :style="{height:'40px'}"
                      v-model="form.validateCode"
                      :placeholder="$t('login.Q4')" />
                  </a-form-model-item>
                </div>
                <div class="validateCodeWrap-code">
                  <div class="main">
                    <img :src="validateCodeImage"/>
                  </div>
                  <div class="action" v-if="!loading">
                    <a-icon type="sync" spin v-if="validateCodeLoading"/>
                    <div class="text" @click.stop="refreshValidateCodeActions">换一张</div>
                  </div>
                </div>
              </div>
          </a-form-model>
          <span>{{ $t("login.Q5") }}</span>
          <a-select v-model="Language" style="width: 250px; margin-bottom: 20px"  @change="handleChange" >
            <a-select-option value="zhcn">简体</a-select-option>
            <a-select-option value="zhtw">繁體</a-select-option>
            <a-select-option value="jajp">日本語</a-select-option>
            <a-select-option value="enus">English</a-select-option>
          </a-select>
          <a-button type="primary" :loading="loading" size="large"  @click="handlelogin" >{{ $t("login.Q6") }}</a-button>
          <div style="text-align: right;margin-top: 8px;">
              <!-- 忘记密码？ -->
              <a @click="toResetPass">{{ $t("login.Q19") }}</a>
          </div>
          </div>

        <div  v-else-if="kind===2" class="password">
          <!-- 重置密码 -->
          <span>{{ $t("login.Q20") }}</span>

          <a-form-model ref="ruleForm2" :model="form2" :rules="rules2">
            <a-form-model-item prop="name">
              <a-input
                ref="username"
                v-model="form2.name"
                style="height: 40px"
                :placeholder="$t('login.Q2')"
              />
            </a-form-model-item>
            <a-form-model-item prop="authcode">
              <!-- 认证码 -->
              <a-input
                ref="authcode"
                v-model="form2.authcode"
                style="height: 40px"
                :placeholder="$t('login.Q21')"
              />
            </a-form-model-item>
            <a-form-model-item prop="email">
              <!-- 邮箱地址 -->
              <a-input
                ref="email"
                v-model="form2.email"
                style="height: 40px"
                :placeholder="$t('login.Q23')"
              />
            </a-form-model-item>
          </a-form-model>
          <!-- 发送 -->
          <a-button type="primary"  :loading="loading"  size="large" @click="resendPass" >{{ $t('login.Q26') }}</a-button >
          <div style="text-align: right; margin-top: 5px;">
            <!-- 返回登录 -->
            <a-button type="primary" @click="toResetLogin" >{{ $t('login.Q27') }}</a-button >
          </div>
          <div>
            <!-- <p>请输入ID与认证码及邮件地址，并点击发送按钮。</p>
            <p>将给事先注册的邮件地址发送新密码1和密码2。 </p> -->
            <p>{{$t('login.Q28')}}</p>
            <p>{{$t('login.Q29')}} </p>
          </div>

        </div>
      </div>
    </div>
    <div class="floorer">
    <!-- <a href="https://beian.miit.gov.cn" target="_blank" >© 2018-2023 bivale.cn 版权所有 ICP证：粤ICP备19017339 号</a> -->
  </div>
  </div>
</template>

<script>
import { validateCodeRequest } from '@/api/user';
import { getRsa,resetPass } from "../../api/user";
import { throttle } from "lodash";
export default {
  data() {
    return {
      loading: false,
      rsa: "",
      form: {
        name: "",
        password1: "",
        validateCode: "",
        locale: "zh_CN",
      },
      validateCodeImage: null,
      validateCodeLoading: false,
      Language: this.$i18n.locale,
      rules: {
        // 用户名
        name: [
          {
            required: true,
            message: () => {
              return this.$t("login.Q8");
            },
            trigger: "change",
          },
        ],
        // 密码
        password1: [
          {
            required: true,
            message: () => {
              return this.$t("login.Q7");
            },
            trigger: "change",
          },
        ],
        // 验证码
        validateCode: [
          {
            required: true,
            message: () => {
              return this.$t("login.Q30");
            },
            trigger: "change",
          },
        ],
      },

      form2: {
        name: "",
        authcode: "",
        email: "",
      },
      rules2: {
        name: [
          {
            required: true,
            message: () => {
              return this.$t("login.Q8");
            },
            trigger: "change",
          },
        ],
        authcode: [
          {
            required: true,
            message: () => {
              return this.$t('login.Q22');//'请输入认证码';
            },
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: () => {
              return this.$t('login.Q24');//'请输入邮箱地址';
            },
            trigger: "change",
          },
          {
            type: 'email',
            message: this.$t('login.Q25'),//'The input is not valid E-mail!',
          },
        ],
      },
      kind:1,
    };
  },
  created() {
    console.log("asdfkhoaklsdhf");
    // 获取服务端Ras公钥
    getRsa()
      .then((res) => {
        // console.log("RSA公钥", res);
        this.rsa = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // 初始化获取验证码
    this.getValidateCode();
  },
  mounted() {
    //进入登录页是焦点在用户名输入框
    this.$refs.username.focus();
  },
  methods: {
    // 刷新验证码
    refreshValidateCodeActions: throttle(function() {
      this.getValidateCode();
    }, 250),
    async getValidateCode() {
      try {
        this.validateCodeLoading = true;
        const result = await validateCodeRequest();
        this.validateCodeLoading = false;
        const blobResult = new Blob([result], {type: 'image/jpeg'});
        this.validateCodeImage = window.URL.createObjectURL(blobResult);
      } catch (err) {
        this.validateCodeLoading = false;
      }
    },
    // 登录
    handlelogin() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            username: this.form.name,
            password1: this.form.password1,
            validateCode: this.form.validateCode,
            rsakey: this.rsa,
            locale: this.Language,
          };
          // console.log("handlelogin par", data);
          this.$store
            .dispatch("user/login", data)
            .then(() => {
              this.loading = false;
              this.$router.push("/");
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //点击切换语言的方法
    handleChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("lang", value);
    },
    toResetPass(){
      this.kind = 2;
      setTimeout(() => {
        this.$refs['ruleForm2'].resetFields();
      }, 100);
    },

    toResetLogin(){
      this.kind = 1;

      setTimeout(() => {
        this.$refs['ruleForm'].resetFields();
      }, 100);
    },
    resendPass(){
      this.$refs['ruleForm2'].validate((valid) => {
        if (valid) {
            let data = {
              username: this.form2.name,
              authcode: this.form2.authcode,
              email: this.form2.email,
            };
            this.loading = true;
            console.log('resendPass',data);
            resetPass(data)
            .then((res) =>{
              console.log('res',res);
              this.loading = false;
              if(res.data.err===0){
                this.$message.success(this.$t('login.ERR0'));
              }else{
                this.$message.error(this.$t('login.ERR'+res.data.err));
              }
            })
            .catch((err)=>{
              this.loading = false;
            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
#login {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.validateCodeWrap {
  display: flex;
  gap: 10px;
  overflow: hidden;
}
.validateCodeWrap-input {
  flex: 2;
}
.validateCodeWrap-code {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;

}
.validateCodeWrap-code .main {
  border: 1px solid #d9d9d9;
  height: 40px;
  width: 100%;
  overflow: hidden;
  background-color: #fdfdfd;
}
.validateCodeWrap-code .main img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  user-select: none;
 -webkit-user-drag: none;
}

.validateCodeWrap-code .action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  line-height: 18px;
  gap: 3px;

}
.validateCodeWrap-code .action .text {
  font-size: 12px;
  user-select: none;
  color: #7682ce;
  cursor: pointer;
}

.validateCodeWrap-code .action .text:hover {
  text-decoration: underline;
}

.left {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.left img {
  width: 100%;
  height: 100%;
}
.right {
  padding-top: 60px;
  width: 550px;
  height: 100%;
  overflow: hidden;
}
.container {
  width: 250px;
  margin: 0px auto;
}
.logo {
  margin-bottom: 54px;
  width: 120px;
  height: 37px;
}
.logo img {
  width: 100%;
  height: 100%;
}
.login {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  margin: 37px 0px;
  color: #7682ce;
}
.login span {
  font-size: 20px;
}
.login a {
  font-size: 12px;
}
.password{
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  margin: 37px 0px;
  color: #7682ce;
}
.password span {
  font-size: 20px;
}
.password p {
  font-size: 10px;
}
.relation {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.relation img {
  margin-right: 7px;
}
.line {
  width: 1px;
  height: 16px;
  background: hsl(300, 5%, 87%);
  margin: 0 10px 0 10px;
}
.floorer{
  position: absolute;
  bottom: 0;
  left: 42.5%;
}

div /deep/.ant-input{
  height: 40px;
}
</style>
